import axios from "@/plugins/axios";

export default class BaseApiServices {

    constructor() {
        if (this.constructor == BaseApiServices) {
            throw new Error("Abstract classes can't be instantiated.");
        }
    }

    getAxiosInstance(){
        return axios;
    }

}
