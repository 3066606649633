import axiosInstance from './../../plugins/axios'

export default {
    namespaced: true,
    state: {
        myStatistics: null, // has all the roles in the database
        myStatisticsDetailed: null,
        myLikes: null,
        likesLoading: false
    },
    getters: {
        geLikesLoading: state => {
            return state.likesLoading;
        },
        getMyLikes: state => {
            /* if (state.myLikes) {
                const likes = {
                    2: { available: true, image: require("@/assets/img/purposes/vote_bronze.png"), likeId: 0 },
                    3: { available: true, image: require("@/assets/img/purposes/vote_silver.png"), likeId: 0 },
                    4: { available: true, image: require("@/assets/img/purposes/vote_gold.png"), likeId: 0 },
                    6: { available: true, image: require("@/assets/img/purposes/vote_platinum.png"), likeId: 0 }
                };

                state.myLikes.forEach(elem => {
                    const currentLike = likes[elem.like];
                    // console.log("element", currentLike, elem.like);
                    if (currentLike) {
                        currentLike.available = false;
                        currentLike.likeId = elem.likeId;
                    }
                });

                return likes;
            } */
            return state.myLikes;
        },
        getCoinsWhithoutSpend: (state, getters) => {
            if (!state.myLikes) return 0;
            return 4 - state.myLikes.length;

            /* if (getters.getMyLikes) {
                return Object.keys(getters.getMyLikes).reduce((total, key) => total + (getters.getMyLikes[key]?.available ? 1 : 0), 0);
            }
            return 0; */
        },
        getMyStatistics: state => state.myStatistics ?? {
            myPurposesCloud: {
                points: 0
            }
        },
        getMyStatisticsDetailed: state => state.myStatisticsDetailed ?? {
            likes: []
        },
        getLikes: (state, getters) => {
            /* const likes = {
                2: { image: require("@/assets/img/purposes/vote_bronze.png"), count: 0, label: "Bronce" },
                3: { image: require("@/assets/img/purposes/vote_silver.png"), count: 0, label: "Plata" },
                4: { image: require("@/assets/img/purposes/vote_gold.png"), count: 0, label: "Oro" },
                6: { image: require("@/assets/img/purposes/vote_platinum.png"), count: 0, label: "Platino" }
            };

            if (state.myStatisticsDetailed) {
                getters.getMyStatisticsDetailed.likes.forEach(elem => {
                    const currentLike = likes[elem.like];
                    if (currentLike) {
                        currentLike.count = elem.count;
                    }
                });
            }

            return likes; */
        }
        /* getTotalPoints: (state, getters) => {
            let totalPoints = 0;
            if (getters.getMyStatistics) {
                if (getters.getMyLikes) {
                    const count = 4 - getters.getCoinsWhithoutSpend;
                    const max = 4;
                    const points = count === max ? 5 : 0;

                    totalPoints += points;
                }

                // statistics for likes/dislike receive for my purposes
                if (getters.getMyStatistics.myPurposesCloud) {
                    const obj = getters.getMyStatistics.myPurposesCloud;
                    totalPoints += obj.points;
                }
            }

            return totalPoints;
        } */

    },
    mutations: {
        setMyStatistics(state, data) {
            state.myStatistics = data
        },
        setMyStatisticsDetailed(state, data) {
            state.myStatisticsDetailed = data
        },
        setLoading(state, loading) {
            state.likesLoading = loading;
        },
        setMyLikes(state, likes) {
            state.myLikes = likes;
        },
        addLike(state, { likeId, like, recognized_user_id, type_recognition }) {
            if (!state.myLikes) state.myLikes = [];
            state.myLikes.push({ likeId, like, recognized_user_id, type_recognition });
        },
        removeLike(state, { likeId, like }) {
            if (state.myLikes) {
                state.myLikes = state.myLikes.filter(elem => elem.likeId != likeId /* && elem.like != like */);
            }
        }
    },
    actions: {
        async loadMyStatistics({ commit, state }) {
            try {
                const myStatistics = await axiosInstance
                    .get('configs/myStatistics');

                commit('setMyStatistics', myStatistics.data);

                return myStatistics.data;
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        },
        async loadMyStatisticsDetailed({ commit, state }) {
            /* try {
                const myStatistics = await axiosInstance
                    .get('configs/myStatisticsDetailed');

                commit('setMyStatisticsDetailed', myStatistics.data);

                return myStatistics.data;
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            } */
        },
        async loadMyLikes({ commit, state }) {
            /* try {
                commit('setLoading', true);

                const myLikes = await axiosInstance
                    .get('votes/myLikes');

                commit('setMyLikes', myLikes.data.likes);

                commit('setLoading', false);
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            } */
        },
        async setLikeLoading({ commit, state }, loading) {
            commit('setLoading', loading);
        },
        async addLike({ commit, state }, { likeId, like, recognized_user_id, type_recognition }) {
            commit('addLike', { likeId, like, recognized_user_id, type_recognition });

            commit('setLoading', false);
        },
        async removeLike({ commit, state }, { likeId, like }) {
            commit('removeLike', { likeId, like });

            commit('setLoading', false);
        }

    }
}
