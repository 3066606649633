<template>
    <div>
        <router-view />
    </div>
</template>

<script>

export default {
    name: "App",
    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: this.$t('global.title'),
            // all titles will be injected into this template
            titleTemplate:
                "%s | " + this.$t('global.subTitle')
        };
    },
    created() {
        this.$store.dispatch('updateCompanyStyle');

        const mq = window.matchMedia("(prefers-color-scheme: dark)");
        mq.addEventListener("change", e => {
            console.log(mq);
            this.$vuetify.theme.dark = e.matches;
        });
        this.getRouterParams();
    },
    mounted() {
        setTimeout(this.fetchData, 500);
    },
    methods: {
        fetchData() {
            this.$store.dispatch('permissions/loadMyPermissions');
            this.$store.dispatch('permissions/loadRoles');
        },
        getRouterParams() {
            // console.log(this.$router.currentRoute.query);
        }
    }
};
</script>
