// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import authenticate from "@/auth/authenticate";
import admin from "@/auth/admin";
import LogAPIService from "@/services/LogAPIService";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) return {selector: to.hash};
        if (savedPosition) return savedPosition;

        return {x: 0, y: 0};
    },
    routes: [
        {
            path: "/",
            component: () => import("@/views/app/Index"),
            beforeEnter: authenticate,
            redirect: "/app/pages/Home",

            children: [

                {
                    path: "/app/pages",
                    component: () => import("@/views/app/pages/Pages"),
                    children: [
                        {
                            path: "home",
                            component: () => import("@/views/app/pages/Home")
                        },
                        {
                            path: "post/:url",
                            component: () => import("@/views/app/pages/PostView")
                        },
                        {
                            path: "game/:url",
                            component: () => import("@/views/app/pages/GamePostView")
                        },
                        {
                            path: "profile",
                            component: () => import("@/views/app/pages/Profile")
                        },
                        {
                            path: "termsAccepted",
                            component: () => import("@/views/app/pages/TermsAccepted")
                        },
                        {
                            path: "legalBasesAccepted",
                            component: () => import("@/views/app/pages/LegalBasesAccepted")
                        },
                        {
                            path: "gifts",
                            component: () => import("@/views/app/pages/MarketPlace")
                        },
                        {
                            path: "statistics",
                            component: () => import("@/views/app/pages/StatisticsDetails")
                        },
                        {
                            path: "ranking",
                            component: () => import("@/views/app/pages/Ranking")
                        },
                        {
                            path: "quiz/:id",
                            component: () => import("@/views/app/pages/GameView")
                        },
                        {
                            path: "quiz",
                            component: () => import("@/views/app/pages/Quiz")
                        }

                    ]
                }
            ]
        },
        {
            path: "/app/admin",
            component: () => import("@/views/app/IndexAdmin"),
            beforeEnter: admin,

            children: [
                {
                    path: "/",
                    component: () => import("@/views/app/pages/Pages"),
                    redirect: "/app/admin/dashboard",
                    children: [
                        {
                            path: "dashboard",
                            component: () => import("@/views/app/pages/admin/DashBoard")
                        },
                        {
                            path: "users",
                            component: () => import("@/views/app/pages/admin/Users")
                        },
                        {
                            path: "roles",
                            component: () => import("@/views/app/pages/admin/Roles")
                        },
                        {
                            path: "companies/create/:id",
                            name: "CompaniesCreate",
                            component: () => import("@/views/app/pages/admin/CompaniesCreate")
                        },
                        {
                            path: "companies",
                            component: () => import("@/views/app/pages/admin/Companies")
                        },
                        {
                            path: "posts/create/:id",
                            name: "PostsCreate",
                            component: () => import("@/views/app/pages/admin/PostsCreate")
                        },
                        {
                            path: "posts",
                            component: () => import("@/views/app/pages/admin/Posts")
                        },
                        {
                            path: "articles/create",
                            name: "ArticleCreate",
                            component: () => import("@/views/app/pages/admin/ArticlesCreate")
                        },
                        {
                            path: "articles",
                            component: () => import("@/views/app/pages/admin/Articles")
                        },
                        {
                            path: "terms/create",
                            name: "TermsCreate",
                            component: () => import("@/views/app/pages/admin/TermsCreate")
                        },
                        {
                            path: "terms",
                            component: () => import("@/views/app/pages/admin/Terms")
                        },
                        {
                            path: "configs",
                            component: () => import("@/views/app/pages/admin/Configs")
                        },
                        {
                            path: "classifiers/card_types",
                            component: () => import("@/views/app/pages/admin/games/classifiers/CardTypes")
                        },
                        {
                            path: "classifiers/game_types",
                            component: () => import("@/views/app/pages/admin/games/classifiers/GameTypes")
                        },
                        {
                            path: "games/create/:id",
                            name: "GameCreate",
                            component: () => import("@/views/app/pages/admin/games/GameCreate")
                        },
                        {
                            path: "games/info/:id",
                            name: "GameInfo",
                            component: () => import("@/views/app/pages/admin/games/GameInfo")
                        },
                        {
                            path: "games",
                            component: () => import("@/views/app/pages/admin/games/Games")
                        },
                        {
                            path: "cards/create",
                            name: "CardCreate",
                            component: () => import("@/views/app/pages/admin/games/CardCreate")
                        },
                        {
                            path: "cards",
                            name: "Cards",
                            component: () => import("@/views/app/pages/admin/games/Cards")
                        }
                    ]
                }
            ]
        },
        {
            path: "/app/sessions",
            component: () => import("@/views/app/sessions/Sessions"),

            children: [
                {
                    path: "error",
                    component: () => import("@/views/app/sessions/Error")
                },
                {
                    path: "forgot",
                    component: () => import("@/views/app/sessions/Forgot")
                },
                {
                    path: "pass-reset",
                    component: () => import("@/views/app/sessions/NewPassword")
                },
                {
                    path: "sign-in-two",
                    name: "login",
                    component: () => import("@/views/app/sessions/SignInUsernameCode")
                },
                {
                    path: "sign-up-2",
                    name: "signup",
                    component: () => import("@/views/app/sessions/SignUpTwo")
                },
                {
                    path: "sign-in-token",
                    component: () => import("@/views/app/sessions/SignInToken")
                }
            ]
        },
        {
            // will match everything (use for not found route error:404)
            path: '*',
            redirect: "/app/pages/Home"
        }
    ]
});

router.beforeEach((to, from, next) => {
    // If this isn't an initial page load.
    if (to.path) {
        // Start the route progress bar.
        store.dispatch("changeThemeLoadingState", true);
    }
    next();
});

router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);


    // Registering Log Access View
    if (!to.path.startsWith("/app/sessions"))
        setTimeout((path) => {
            LogAPIService.registerAccessView({view: path});
        }, 2000, to.path);

});

export default router;
