export default {
    getDateTimeTableFormat(date) {
        const separator = '-'

        if (!date) { return null }
        const fullDate = date.split('T')
        const dateF = fullDate[0]
        const [hour, minutes] = fullDate[1].split(':')

        const [year, month, day] = dateF.split('-')
        return `${day}${separator}${month}${separator}${year} ${hour}:${minutes}`
    },
    /**
   * @params: the BD format '2021-06-28 10:48:28' (locale +00)
   * @return: the format for show and with the correct locale
   * */
    getDateTimeToFormatAndLocale(date) {
        return date;
    },

    /**
   * @params: Example: "2021-09-13 14:12:27"
   * @return: "13/09 14:12"
   * */
    getDateTimeToSingleFormatWithoutYear(date) {
        return date ? date.replace(/\d+-(\d+)-(\d+)\s(\d+):(\d+).*/, '$2/$1 $3:$4') : '';
    },
    isADateTime(date) {
        if (!date) { return false }
        const fullDate = date.split('T');
        if (fullDate.length !== 2) return false;

        const fullTime = fullDate[1].split(':');
        if (fullTime.length < 3) return false;

        const [hour, minutes] = fullTime;
        if (hour.length < 2 || minutes.length < 2) return false;

        const dateF = fullDate[0].split('-');
        if (dateF.length !== 3) return false;
        const [year, month, day] = dateF;
        if (year.length !== 4 || month.length !== 2 || day.length !== 2) return false;

        return true
    },
    dateToString(date) {
        if (!date) { return '' }

        //  const offset = date.getTimezoneOffset();
        // date = new Date(date.getTime() - (offset * 60 * 1000));
        return date.toISOString().split('T')[0];
    },
    currentDateTime() {
        return new Date().toISOString().split('.')[0].replace('T', ' ');
    },
    currentDateTimeToFileName() {
        return this.currentDateTime().replaceAll(':', '.');
    },
    showDiffForHumans(date1, date2, twoLines) {
        if (date1 instanceof Date) {
            date1 = date1.getTime();
        }
        if (date2 instanceof Date) {
            date2 = date2.getTime();
        }

        if (typeof date1 !== "number" || typeof date2 !== "number") return "";

        /* const date1 = new Date();
        const date2 = new Date("2021/09/30 23:59:59"); */

        // Customise date2 for your required future time

        let diff = (date2 - date1) / 1000;
        diff = Math.abs(Math.floor(diff));

        const days = Math.floor(diff / (24 * 60 * 60));
        let leftSec = diff - days * 24 * 60 * 60;

        const hrs = Math.floor(leftSec / (60 * 60));
        leftSec = leftSec - hrs * 60 * 60;

        const min = Math.floor(leftSec / (60));
        leftSec = leftSec - min * 60;

        // return days + " días<br>" + hrs + " horas<br>" + min + " minutos ";

        if (twoLines) { return days + " días " + hrs + " H<br>" + min + " min " + leftSec + " S"; }
        return days + " días<br>" + hrs + " horas<br>" + min + " min<br>" + leftSec + " seg";
    },
    getTimeForHumans(seconds) {
      let diff = seconds;

      const days = Math.floor(diff / (24 * 60 * 60));
      let leftSec = diff - days * 24 * 60 * 60;

      const hours = Math.floor(leftSec / (60 * 60));
      leftSec = leftSec - hours * 60 * 60;

      const minutes = Math.floor(leftSec / (60));
      leftSec = leftSec - minutes * 60;


      return {
        days,
        hours,
        minutes,
        seconds: leftSec
      }
    },
    joinDateTime(date, time, separator = '-'){
      if (!date) { return null }

      const fullDate = date.split('T');
      const dateF = fullDate[0];

      const [year, month, day] = dateF.split('-');

      let timeFull = time;
      if (!timeFull) {
        timeFull = '00:00';
      }
      const [hour, minutes, seconds = '00'] = timeFull.split(':');

      // console.log('joinDateTime', `${year}${separator}${month}${separator}${day} ${hour}:${minutes}:${seconds}` )

      return `${year}${separator}${month}${separator}${day}T${hour}:${minutes}:${seconds}`
    },
    joinDateTimeToISOString(date, time, separator = '-'){
      if (!date) { return null }

      const fullDate = date.split('T');
      const dateF = fullDate[0];

      const [year, month, day] = dateF.split('-');

      let timeFull = time;
      if (!timeFull) {
        timeFull = '00:00';
      }
      const [hour, minutes, seconds = '00'] = timeFull.split(':');

      const dateObj = new Date(+year, month - 1, +day, +hour, +minutes, +seconds);

      return dateObj.toISOString();

    },
    /**
     * @params date: Example '2022-10-16T15:16:00+00:00'
     *
     * @return 2022-10-16
     * */
    getDateStringLocale(dateString) {
      let event;
      if (!dateString) { event = new Date(); }
      else{ event = new Date(dateString); }

      const month = event.getMonth() + 1;
      const monthString = (month < 10 ? '0' : '') + month;

      const day = event.getDate();
      const dayString = (day < 10 ? '0' : '') + day;

      return `${event.getFullYear()}-${monthString}-${dayString}`;
    },
    /**
     * @params date: Example '2022-10-16T15:16:00+00:00'
     *
     * @params locale:
     * default 'eu'  output: 01:15:30
     * Example 'en-US' Example output: 1:15:30 AM
     * */
    getTimeStringLocale(dateString, withSeconds = false, locale = 'eu') {
      let event;
      if (!dateString) { event = new Date(); }
      else{ event = new Date(dateString); }

      let result = event.toLocaleTimeString(locale);

      if(!withSeconds) result = result.substring(0, 5)

      return result;
    },
    getTime(date) {
      if (!date) { return '' }

      const fullDate = date.split('T');
      if (fullDate.length !== 2) return '';

      const fullTime = fullDate[1].split(':');
      if (fullTime.length < 2) return '';

      const [hour, minutes] = fullTime;
      if (hour.length < 2 || minutes.length < 2) return '';

     return `${hour}:${minutes}`
    },
  getDateTimeStringLocale(dateString, withSeconds = false, locale = 'eu'){
      return this.getDateStringLocale(dateString) +' '+ this.getTimeStringLocale(dateString, withSeconds , locale )
  }
    /* formatDate(date, separator = '-') {
        if (!date) { return null }
        const [year, month, day] = date.split('-')

        if (year.length > 2) { return `${day}${separator}${month}${separator}${year}` }

        return `${year}${separator}${month}${separator}${day}`
    },
    formatDateFromBackend(date) {
        if (!date) { return null }
        const fullDate = date.split('T')[0]
        const [year, month, day] = fullDate.split('-')
        return `${day}-${month}-${year}`
    },
    formatDateTimeFromBackend(date, separator = '-') {
        if (!date) { return null }
        const fullDate = date.split('T')
        const dateF = fullDate[0]
        const [hour, minutes] = fullDate[1].split(':')

        const [year, month, day] = dateF.split('-')
        return `${day}${separator}${month}${separator}${year} | h${hour}:${minutes}`
    },
    parseDate(date) {
        if (!date) { return null }
        const from = date.split('-')
        return new Date(Date.UTC(from[2], from[1] - 1, from[0])).toISOString().substr(0, 10)
    },
    parseDateCEST(date, separator) {
    // Example of imput: Sat Jul 20 00:00:00 CEST 2019
        if (!date) { return null }

        date = date.replace(/CEST/, '+0200')
        date = new Date(date).toISOString().substr(0, 10)

        const [year, month, day] = date.split('-')
        if (year.length > 2) { return `${day}${separator}${month}${separator}${year}` }
        return `${year}${separator}${month}${separator}${day}`
    }// "2020-11-30T11:13:31.713Z" */
}
