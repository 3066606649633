export default {
    languageToDisplay(value) {
        switch (value) {
        case 'es':
            return 'Español';
        case 'en':
            return 'English';
        }
    },
    /**
   * @params: a text with possibles tags
   * @return: a text withouth the text tags, only the component innerHTML recursively
   * */
    stripTags(text) {
        return text.replace(/(<([^>]+)>)/ig, "");
    },

    isAvailableButton({ post, index, indexShowPost }) {
        return (/*index <= indexShowPost &&*/ post.attributes.isActive);
    },
    getPostURL({ post }) {
        if (!(post?.attributes?.url)) return '';

        let beforeRoute = '/app/pages/';

        if (post.attributes.game_id) {
            beforeRoute += 'game/';
        } else if (post.attributes.body) {
            beforeRoute += 'post/';
        } else if (post.attributes.url === 'ranking') {
            // maintaining the route
        } else if (post.attributes.url === 'quiz') {
        // maintaining the route
        }
        else {
            // resolve when the post dont have body and is not a game
            beforeRoute += 'post/';
        }

        return beforeRoute + post.attributes.url;
    }
}
