import BaseApiServices from "@/services/BaseApiServices";

class LogAPIService extends BaseApiServices {


    async registerAccessView({view}) {
        try {
            const url = 'logs/logView';

            await this.getAxiosInstance().post(url, {view});
        } catch (e) {
        }

    }

}

export default new LogAPIService();
