import axiosInstance from './../../plugins/axios'

export default {
    state: {
        loggedInUser:
      localStorage.getItem('userInfo') != null
          ? JSON.parse(localStorage.getItem('userInfo'))
          : null,
        loading: false,
        error: null
    },
    getters: {
        loggedInUser: state => state.loggedInUser, // token, user, roles, company
        getUser: state => state.loggedInUser?.user ?? {
            id: "",
            username: "",
            name: "",
            lastname: "",
            email: "",
            picture: "",
            email_verified_at: null,
            created_at: "",
            updated_at: "",
            index_show_post: 0, // this is the maximum index for show the posts
            company_id: null
        },
        getUserPicture: (state, getters) => {
            const picture = getters.getUser.picture;
            return !picture ? ''
                : process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=users/images/' + picture;
        },
        getUserAcronym: (state, getters) => {
            let acronym = "";
            const username = getters.getUserName;
            if (username) {
                acronym = username.substring(0, 1).toUpperCase();
                acronym += username.substring(1).split("").find(e => e.toUpperCase() === e) ?? "";
            }
            return acronym;
        },
        getUserId: state => state.loggedInUser?.user?.id ?? '',
        getUserName: state => state.loggedInUser?.user?.username ?? '',
        getUserRoles: state => state.loggedInUser?.roles ?? [],
        isAdmin: (state, getters) => !!getters.getUserRoles.find(r => r.name === 'Admin'),
        getToken: state => state.loggedInUser?.token ?? '',
        loading: state => state.loading,
        error: state => state.error,
        getIndexShowPost: state => state.loggedInUser?.user?.index_show_post ?? 0,
        getMyGroups: state => state.loggedInUser?.myGroups ?? [],
        getMyGroupRoot: state => state.loggedInUser?.myGroupRoot ?? '',
        getCompanyId: (state, getters) => getters.getUser?.company_id,
        getUserCompany: (state, getters) => getters.loggedInUser?.company ?? {
            id: null,
            name: null,
            title: null,
            logo: null,
            color_appbar: null,
            color_background: null,
            color_primary: null,
            color_secondary: null,
            config: null,
            banner: null,
            banner_mobile: null
        }
    },
    mutations: {
        setToken(state, token) {
            state.loggedInUser = { token }
        },
        setUser(state, data) {
            state.loggedInUser = data
            state.loading = false
            state.error = null

            localStorage.setItem('userInfo', JSON.stringify(state.loggedInUser))
        },
        setUserSingle(state, user) {
            // user.company_id = state.loggedInUser?.user?.company_id;

            if (state.loggedInUser) {
                const oldUser = state.loggedInUser.user;
                const newUser = {...oldUser, ...user};

                state.loggedInUser.user = newUser;
            } else {
                state.loggedInUser = { user }
            }

            state.loading = false
            state.error = null

            localStorage.setItem('userInfo', JSON.stringify(state.loggedInUser))
        },
        setLogout(state) {
            state.loggedInUser = null
            state.loading = false
            state.error = null
            // this.$router.go("/");
        },
        setLoading(state, data) {
            state.loading = data
            state.error = null
        },
        setError(state, data) {
            state.error = data
            state.loggedInUser = null
            state.loading = false
        },
        clearError(state) {
            state.error = null
        }
    },
    actions: {
        async login({ commit, dispatch }, data) {
            commit('clearError')
            commit('setLoading', true)

            try {
                const login = await axiosInstance
                    .post('auth/login', {
                        username: data.username,
                        password: data.password
                    })
                /* const token = login.data.token
        const username = login.data?.username
        const roles = login.data?.roles

        const user = {
            token,
            username,
            roles
        }; */

                await dispatch('setUser', login.data);

                // await dispatch('permissions/loadMyPermissions');
                await commit('permissions/setMyPermissions', login.data.permissions);

                await dispatch('updateCompanyStyle');

                commit('setLoading', false);

                return true;
            } catch (error) {
                commit('setLoading', false);
                if (error.response) {
                    return error.response.data;
                    /* console.log(error.response.data.error) // "invalid_credentials"
          console.log(error.response.status)
          console.log(error.response.headers) */
                }
                return 'connection_issues';
            }
        },
        async loadProfile({ commit, dispatch, getters }, token = null) {
            commit('clearError')
            commit('setLoading', true)

            if (token?.length) {
                commit('setToken', token);
            }

            try {
                const loadProfile = await axiosInstance
                    .get('users/profile')

                if (loadProfile?.data?.user) {
                    const data = { ...(loadProfile.data), token }
                    await dispatch('setUser', data);

                    await dispatch('updateCompanyStyle');

                    commit('setLoading', false);

                    return "OK";
                }

                return loadProfile?.data;
            } catch (error) {
                commit('setLoading', false);
                if (error.response) {
                    return error.response.data;
                    /* console.log(error.response.data.error) // "invalid_credentials"
console.log(error.response.status)
console.log(error.response.headers) */
                }
                return 'connection_issues';
            }
        },
        setUser({ commit }, { token, user, roles, permission, company }) {
            const userData = {
                token,
                user,
                roles,
                permission,
                company
            };
            commit('setUser', userData);
        },
        async setUserSimple({ getters, dispatch, commit }, user) {

            commit('setUserSingle', user);
        },
        async setIndexShowPost({ state, getters, commit }, indexShowPost) {
            if (getters.getIndexShowPost < indexShowPost) {
                const user = getters.getUser;
                user.index_show_post = indexShowPost;

                commit('setUserSingle', user);

                try {
                    axiosInstance
                        .post('configs/updateIndexShowPost', {
                            index_show_post: indexShowPost
                        })
                } catch (e) {
                }
            }
        },
        signUserUp({ commit }, data) {
            commit('setLoading', true)
            commit('clearError')

            // data.email, data.password

            /** OK
       {
                    commit("setLoading", false);

                    const newUser = {
                        uid: user.user.uid
                    };
                    console.log(newUser);
                    localStorage.setItem("userInfo", JSON.stringify(newUser));
                    commit("setUser", newUser);
                }
       * */

            /** ERROR
       {
                    commit("setLoading", false);
                    commit("setError", error);
                    localStorage.removeItem("userInfo");
                    console.log(error);
                }
       * */
        },
        signOut({ commit, dispatch }) {
            localStorage.removeItem("userInfo");
            commit("setLogout");
            dispatch('loading/end');
            dispatch('snackbar/hideSnackbar')
        }/*,
        async fetchMyGroups({ commit, dispatch }) {
            try {
                const groups = await axios
                    .get('/myGroups')

                const data = groups.data;

                commit("setMyGroups");
            } catch (e) {
                dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        } */
    }
}
