import axios from '@/plugins/axios'

export default {
    namespaced: true,
    state: {
        config: {
            companyIdSelected: null,
            competition: { isOpen: true, start: null, end: null },

            recognition: { isOpen: null, start: null, end: null },
            votation: { isOpen: null, start: null, end: null },
            nominated: { wasNominated: false }
        }
    },
    getters: {
        /* getPostsOrdered: (state, getters, rootState, rootGetters) => {
            const lang = rootGetters["languages/getLang"];

            return state.posts
                .sort((a, b) => parseInt(a.attributes.order) > parseInt(b.attributes.order) ? 1 : -1)
                .filter(post => {
                    if (post.attributes.lang === lang) return true;
                });

        }, */

        getConfigKeyValue: state => state.config,
        wasNominated: state => state.config.nominated.wasNominated
    },
    mutations: {
        setConfigKeyValueSingle(state, { key, value }) {
            state.config[key] = value;

        // console.log('state.config[key]', state.config[key], key, value)
        },
        setConfigKeyValue(state, { key, value }) {
            state.config[key] = { ...state.config[key], ...value };

            // console.log('state.config[key]', state.config[key], key, value)
        }
    },
    actions: {
        async setConfigKeyValue({ commit }, { key, value }) {
            commit('setConfigKeyValueSingle', { key, value });
        },
        async loadConfigGeneral({ commit, state }) {
            try {
                // if (state.getConfigKeyValue.competition.end instanceof Date) return state.getConfigKeyValue;

                const configs = await axios
                    .get('configs/getConfigGeneral'); // ?filter[name]=competition

                // console.log('loadDateCloseCompetition ', configs?.data?.data);
                const isOpenCompetition = configs?.data.isOpenCompetition;
                commit('setConfigKeyValue', { key: 'competition', value: { isOpen: isOpenCompetition } });

                const configGenerals = configs?.data.config_generals;
                if (configGenerals.length) {
                    configGenerals.forEach(cConf => {
                        const name = cConf.name;
                        let options = cConf.options;
                        if (options) {
                            options = JSON.parse(options);

                            let value = {};

                            switch (name) {
                            case 'competition':
                                value = {
                                    start: options?.start ? new Date(options.start.replace(" ", "T")) : null,
                                    end: options?.end ? new Date(options.end.replace(" ", "T")) : null
                                };
                                break;
                            }

                            commit('setConfigKeyValue', { key: name, value });
                        }
                    });
                }

                // console.log(' ********* state.config ******* ', state.config);
                return state.config;
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        },
        async isOpenRecognition({ dispatch, state }) {
            let isOpen = state.config.recognition.isOpen;

            if (isOpen === null) {
                await dispatch('loadConfigGeneral');
                isOpen = state.config.recognition.isOpen;
            }

            return isOpen;

            /* let end = state.config.competition.end;
            if (!end) {
                await dispatch('loadConfigGeneral');
                end = state.config.competition.end;
            }

            const dateNow = Date.now();

            if (end) {
                return end >= dateNow;
            }

            return null;
            */
        },
        async isOpenVotation({ dispatch, state }) {
            let isOpen = state.config.votation.isOpen;

            if (isOpen === null) {
                await dispatch('loadConfigGeneral');
                isOpen = state.config.votation.isOpen;
            }

            return isOpen;
        }
    }
}
