import axiosInstance from './../../plugins/axios'

function can(state, key) {
    if (!state.myPermissions?.map) return false;
    const perm = state.myPermissions.map[key];
    return (!!perm || perm === 0);
}

export default {
    namespaced: true,
    state: {
        roles: null, // has all the roles in the database
        permissions: null, // has all the permissions in the database
        myPermissions: null // has te user permission (including the permission in the roles of this user)
    },
    getters: {
        getRoles: state => state.roles ?? [],
        getPermissions: state => state.permissions ?? [],
        getMyPermissions: state => {
            if (state.myPermissions) return state.myPermissions;
            const empty = [];
            empty.map = {};
            return empty;
        },
        isAdmin: (state, getters, rootState, rootGetters) => rootGetters.isAdmin,
        canUpdatePosts: (state, getters) => getters.isAdmin || can(state, "Update posts"),
        canManageGames: (state, getters) => getters.isAdmin || can(state, "Manage games")

    },
    mutations: {
        setRoles(state, data) {
            state.roles = data
        },
        setPermissions(state, data) {
            state.permissions = data
        },
        setMyPermissions(state, data) {
            state.myPermissions = data;

            const map = {};
            data.forEach(p => { map[p.name] = p.id });
            state.myPermissions.map = map;
        }
    },
    actions: {
        async loadRoles({ commit, state }) {
            try {
                if (state.roles) return state.roles;

                const roles = await axiosInstance
                    .get('roles');

                commit('setRoles', roles.data?.data);

                return roles.data?.data;
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        },
        async loadPermissions({ commit, state }) {
            try {
                if (state.permissions) return state.permissions;

                const permissions = await axiosInstance
                    .get('permissions');

                commit('setPermissions', permissions.data?.data);

                return permissions.data?.data;
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        },
        async loadMyPermissions({ commit, state, getters, rootGetters }) {
            try {
                if (state.permissions || !rootGetters.getToken) return getters.getPermissions;

                const permissions = await axiosInstance
                    .get('users/myPermissions');

                commit('setMyPermissions', permissions.data);

                return permissions.data;
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error);
                    console.log(error.response.status);
                }
            }
        }

    }
}
